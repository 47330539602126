export const trackingActionClickCTAMenu = 'CTA Click Menu'

export const trackingActionClickCTA = 'CTA Click'

export const trackingActionClickBanner = 'Click bannière le saviez-vous'

export const trackingActionClickPageBottom = 'Click CTA bas de page'

// Post thumbnail
export const trackingActionClickPostThumbnail = 'Click article'
